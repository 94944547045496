

.intro-text {
padding-top: 100px;
padding-bottom: 100px;
color: white;
}

@media (min-width: 768px) {
.intro-text {
padding-top: 200px;
padding-bottom: 200px;
}
}

.intro-text .intro-lead-in {
font-size: 22px;
/* text-shadow:  2px 2px black; */
line-height: 22px;
margin-bottom: 25px;
font-family: "Montserrat","Helvetica Neue",Helvetica,Arial,sans-serif;
}


.intro-text .intro-heading {
font-size: 50px;
font-weight: 700;
/* text-shadow:  2px 2px black; */
line-height: 50px;
margin-bottom: 25px;
font-family: "Montserrat","Helvetica Neue",Helvetica,Arial,sans-serif;
}
@media (min-width: 768px) {
    header.masthead .intro-text .intro-heading {
    font-size: 75px;
    font-weight: 700;
    line-height: 75px;
    margin-bottom: 50px;
    font-family: "Montserrat","Helvetica Neue",Helvetica,Arial,sans-serif;
    }
    }
@media (min-width: 768px) {
    .intro-text .intro-lead-in {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 25px;
    font-family: "Montserrat","Helvetica Neue",Helvetica,Arial,sans-serif;
    }
    }

